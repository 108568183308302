/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-telegram': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.71C13.256.243 11.11.02 9 0 6.89.019 4.744.243 3.568.692 1.386 1.539.667 3.752.518 4.303c0 .003.002-.003-.007.03a8.063 8.063 0 00-.053.224 18.961 18.961 0 00-.456 4.434c-.018 1.366.085 2.888.442 4.4a20.084 20.084 0 00.075.305l.014.052c.177.627.916 2.737 3.035 3.56 1.176.449 3.323.673 5.432.692 2.11-.019 4.256-.224 5.432-.692 2.181-.847 2.9-3.059 3.05-3.611 0-.003-.002.003.006-.03l.022-.085.032-.14c.35-1.528.474-3.069.456-4.433.018-1.378-.087-2.914-.434-4.42l-.035-.152a7.122 7.122 0 00-.023-.091l-.009-.035c-.155-.571-.88-2.759-3.046-3.6z" _fill="#898B9B"/><path pid="1" d="M14.23 4.04L3.255 8.315a.4.4 0 00.032.757l2.789.824 1.04 3.302c.11.345.546.45.8.191l1.441-1.472 2.83 2.077a.59.59 0 00.927-.355l1.873-8.959a.565.565 0 00-.757-.642zm-1.376 1.988l-5.098 4.508a.274.274 0 00-.09.174l-.197 1.745c-.006.057-.086.064-.103.01L6.56 9.863a.274.274 0 01.117-.314l6.02-3.734c.138-.086.28.105.158.213z" _fill="#fff"/>'
  }
})
